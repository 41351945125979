/* eslint-disable no-empty-pattern */
import React from 'react'
import { Layout, SEO } from '../../ui'

function LogoutView() {
  return (
    <Layout>
      <SEO title="Logout" />
    </Layout>
  )
}

export default LogoutView
